











































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
} from "@vue/composition-api";
import { Applicant } from "@/store";
import moment from "moment";
import { ApplicantActions, ApplicantGetters } from "@/store/applicant";
import {
  APPLICANT_STATUS,
  APPLICANT_STATUS_DISPLAY_TEXT,
} from "@/store/constants";

import {
  useStore,
  useApplicantService,
  useRouter,
} from "@/composables/providers";

interface Props {
  isStaffOrHigher: boolean;
}
export default defineComponent({
  name: "ApplicantsDashboard",
  props: {
    isStaffOrHigher: Boolean,
  },
  setup(props: Props) {
    const store = useStore();
    const router = useRouter();
    const service = useApplicantService();

    const loading = computed<boolean>(
      () => store.getters[ApplicantGetters.LOADING]
    );

    onBeforeMount(async () => {
      if (props.isStaffOrHigher) {
        await store.dispatch(ApplicantActions.LIST, {
          service: service,
        });
      }
    });

    function transformDateTime(date: string) {
      return moment(date).format("LLL");
    }

    async function navigateToApplicantList() {
      await router.push({
        name: "ApplicantList",
      });
    }

    async function navigateToApplicant(id: number, update = false) {
      await store.dispatch(ApplicantActions.SELECT, {
        id: id,
        service,
      });
      await router.push({
        name: update ? "ApplicantUpdate" : "ApplicantDetail",
        params: { id: id.toString() },
      });
    }

    const applicantsRecentlyModified = computed<Applicant | undefined>(
      () => store.getters[ApplicantGetters.RECENTLY_MODIFIED]
    );

    const applicantExpanded = ref<boolean>(true);

    function getDate(date: Date) {
      return moment(new Date(date).toISOString().substr(0, 10)).format(
        "MM/DD/YYYY"
      );
    }

    return {
      store,
      router,
      loading,
      navigateToApplicant,
      navigateToApplicantList,
      transformDateTime,
      applicantExpanded,
      applicantsRecentlyModified,
      APPLICANT_STATUS,
      APPLICANT_STATUS_DISPLAY_TEXT,
      props,
      getDate,
    };
  },
});

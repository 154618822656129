






























































































import { computed, defineComponent, ref } from "@vue/composition-api";
import { ApplicantGetters } from "@/store/applicant";
import {
  APPLICANT_STATUS,
  APPLICANT_STATUS_DISPLAY_TEXT,
} from "@/store/constants";

import { useStore, useRouter } from "@/composables/providers";
import { DogGetters } from "@/store/dog";

interface Props {
  isStaffOrHigher: boolean;
}
export default defineComponent({
  name: "MetricsDashboard",
  props: {
    isStaffOrHigher: Boolean,
  },
  setup(props: Props) {
    const store = useStore();
    const router = useRouter();

    const loading = computed<boolean>(
      () =>
        store.getters[ApplicantGetters.LOADING] ||
        store.getters[ApplicantGetters.LOADING]
    );

    const applicantsTotalInProcess = computed<number | undefined>(
      () => store.getters[ApplicantGetters.TOTAL_APPLICANTS_IN_PROCESS]
    );

    const applicantsTotal = computed<number | undefined>(
      () => store.getters[ApplicantGetters.TOTAL_ACTIVE]
    );

    const dogsTotalInTraining = computed<number | undefined>(
      () => store.getters[DogGetters.TOTAL_DOGS_IN_TRAINING]
    );

    const dogsTotalNeedingRecertifications = computed<number | undefined>(
      () => store.getters[DogGetters.TOTAL_DOGS_NEEDING_RECERTIFICATIONS]
    );

    const dogsTotalNeedingVaccinations = computed<number | undefined>(
      () => store.getters[DogGetters.TOTAL_DOGS_NEEDING_VACCINATIONS]
    );

    const dogsTotal = computed<number | undefined>(
      () => store.getters[DogGetters.TOTAL_ACTIVE]
    );

    async function navigateToReportList() {
      await router.push({
        name: "ReportList",
      });
    }

    const metricsExpanded = ref<boolean>(true);
    return {
      store,
      router,
      loading,
      metricsExpanded,
      APPLICANT_STATUS,
      APPLICANT_STATUS_DISPLAY_TEXT,
      applicantsTotalInProcess,
      applicantsTotal,
      dogsTotalInTraining,
      dogsTotalNeedingRecertifications,
      dogsTotalNeedingVaccinations,
      dogsTotal,
      navigateToReportList,
      props,
    };
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"600","loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.navigateToDogList}},[_c('v-icon',[_vm._v("mdi-view-list")])],1),_c('v-toolbar-title',[_vm._v("Support Dogs")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dogExpanded = !_vm.dogExpanded}}},[(_vm.dogExpanded)?_c('v-icon',[_vm._v("mdi-arrow-collapse")]):_c('v-icon',[_vm._v("mdi-arrow-expand")])],1)],1),(_vm.dogExpanded)?_c('v-list',{attrs:{"two-line":"","subheader":""}},[_c('v-subheader',[_vm._v("Re-Certifications Needed")]),_vm._l((_vm.dogsRecertificationNeeded),function(dog){return _c('v-list-item',{key:dog.id,on:{"click":function($event){return _vm.navigateToDog(dog.id)}}},[_c('v-list-item-icon',{attrs:{"title":dog.trainingCertExpirationInDays &&
          dog.trainingCertExpirationInDays > 0
            ? dog.trainingCertExpirationInDaysAbsAndRnd +
              ' days until certification expires'
            : dog.trainingCertExpirationInDays &&
              dog.trainingCertExpirationInDays <= 0
            ? dog.trainingCertExpirationInDaysAbsAndRnd +
              ' days since certification expired'
            : ''}},[(!dog.trainingCertExpires)?_c('v-progress-circular',{staticClass:"text-center",attrs:{"value":"0"}}):(dog.trainingCertExpirationInDays > 0)?_c('v-progress-circular',{staticClass:"text-center",attrs:{"value":dog.trainingCertExpirationInDaysAbsAndRnd * 3.33,"color":"warning"}},[_vm._v(_vm._s(dog.trainingCertExpirationInDaysAbsAndRnd))]):_c('v-progress-circular',{staticClass:"text-center",attrs:{"value":dog.trainingCertExpirationInDaysAbsAndRnd * 3.33,"color":dog.trainingCertExpires &&
            dog.trainingCertExpirationInDaysAbsAndRnd > 30
              ? 'error darken-1'
              : 'error'}},[_vm._v(_vm._s(dog.trainingCertExpirationInDaysAbsAndRnd))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(dog.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(dog.trainingCertExpires ? _vm.getDate(_vm.transformDateTime(dog.trainingCertExpires)) : "")+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.navigateToDog(dog.id, true)}}},[_c('v-icon',{attrs:{"color":"secondary lighten-5"}},[_vm._v("mdi-pencil-outline")])],1)],1)],1)})],2):_vm._e(),(_vm.dogExpanded)?_c('v-list',{attrs:{"two-line":"","subheader":""}},[_c('v-subheader',[_vm._v("Vaccinations Needed")]),_vm._l((_vm.dogsVaccinationNeeded),function(dog){return _c('v-list-item',{key:dog.id,on:{"click":function($event){return _vm.navigateToDog(dog.id)}}},[_c('v-list-item-icon',{attrs:{"title":dog.oldestVaccinationInDays && dog.oldestVaccinationInDays > 0
            ? dog.oldestVaccinationInDaysAbsAndRnd +
              ' days until vaccination expires'
            : dog.oldestVaccinationInDays && dog.oldestVaccinationInDays <= 0
            ? dog.oldestVaccinationInDaysAbsAndRnd +
              ' days since vaccination expired'
            : ''}},[(!dog.oldestVaccinationInDays)?_c('v-progress-circular',{staticClass:"text-center",attrs:{"value":"0"}}):(dog.oldestVaccinationInDays > 0)?_c('v-progress-circular',{staticClass:"text-center",attrs:{"value":dog.oldestVaccinationInDaysAbsAndRnd * 3.33,"color":"warning"}},[_vm._v(_vm._s(dog.oldestVaccinationInDaysAbsAndRnd))]):_c('v-progress-circular',{staticClass:"text-center",attrs:{"value":dog.oldestVaccinationInDaysAbsAndRnd * 3.33,"color":dog.oldestVaccinationInDays &&
            dog.oldestVaccinationInDaysAbsAndRnd > 30
              ? 'error darken-1'
              : 'error'}},[_vm._v(_vm._s(dog.oldestVaccinationInDaysAbsAndRnd))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(dog.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(dog.oldestVaccinationInDays ? _vm.getDate(_vm.transformDateTimeAddDays(dog.oldestVaccinationInDays)) : "")+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.navigateToDog(dog.id, true)}}},[_c('v-icon',{attrs:{"color":"secondary lighten-5"}},[_vm._v("mdi-pencil-outline")])],1)],1)],1)})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }



































import { defineComponent, computed } from "@vue/composition-api";
import { useStore } from "@/composables/providers";
import { SessionGetters } from "@/store/session";
import ApplicantsDashboard from "@/views/applicants/Dashboard.vue";
import MetricsDashboard from "@/views/metrics/Dashboard.vue";
import DogsDashboard from "@/views/dogs/Dashboard.vue";
import DogTrainingsList from "@/views/dog-training-entries/List.vue";
import { RootGetters } from "@/store";

export default defineComponent({
  name: "DashboardDetails",
  components: {
    ApplicantsDashboard,
    MetricsDashboard,
    DogsDashboard,
    DogTrainingsList,
  },
  setup() {
    const store = useStore();
    const sessionLoading = computed(
      () => store.getters[SessionGetters.LOADING]
    );
    const appLoading = computed<boolean>(
      () => store.getters[RootGetters.LOADING]
    );
    const loading = computed<boolean>(
      () => appLoading.value || sessionLoading.value
    );
    const isStaffOrHigher = computed(
      () => store.getters[SessionGetters.IS_STAFF_OR_HIGHER]
    );

    return {
      isStaffOrHigher,
      loading,
    };
  },
});
















































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
} from "@vue/composition-api";
import { Dog } from "@/store";
import moment from "moment";
import { DogActions, DogGetters } from "@/store/dog";
import {
  APPLICANT_STATUS,
  APPLICANT_STATUS_DISPLAY_TEXT,
} from "@/store/constants";

import { useStore, useDogService, useRouter } from "@/composables/providers";

interface Props {
  isStaffOrHigher: boolean;
}
export default defineComponent({
  name: "DogsDashboard",
  props: {
    isStaffOrHigher: Boolean,
  },
  setup(props: Props) {
    const store = useStore();
    const router = useRouter();
    const service = useDogService();
    const loading = computed<boolean>(() => store.getters[DogGetters.LOADING]);
    const currentDateMilliseconds = computed<number>(() =>
      new Date().getTime()
    );

    onBeforeMount(async () => {
      if (props.isStaffOrHigher) {
        await store.dispatch(DogActions.LIST, {
          service: service,
        });
      }
    });

    function transformDateTime(date: string) {
      return moment(date).format("LLL");
    }

    function transformDateTimeAddDays(daysToAdd: number) {
      return moment().add(daysToAdd, "d").format("LLL");
    }

    async function navigateToDogList() {
      await router.push({
        name: "DogList",
      });
    }

    async function navigateToDog(id: number, update = false) {
      await store.dispatch(DogActions.SELECT, {
        id: id,
        service,
      });
      await router.push({
        name: update ? "DogUpdate" : "DogDetail",
        params: { id: id.toString() },
      });
    }

    const dogsRecertificationNeeded = computed<Dog | undefined>(
      () => store.getters[DogGetters.RECERTIFICATION_NEEDED]
    );

    const dogsVaccinationNeeded = computed<Dog | undefined>(
      () => store.getters[DogGetters.VACCINATION_NEEDED]
    );

    const dogExpanded = ref<boolean>(true);

    function getDate(date: Date) {
      return moment(new Date(date).toISOString().substr(0, 10)).format(
        "MM/DD/YYYY"
      );
    }

    return {
      currentDateMilliseconds,
      store,
      router,
      loading,
      navigateToDog,
      navigateToDogList,
      transformDateTimeAddDays,
      transformDateTime,
      dogExpanded,
      dogsRecertificationNeeded,
      dogsVaccinationNeeded,
      APPLICANT_STATUS,
      APPLICANT_STATUS_DISPLAY_TEXT,
      props,
      getDate,
    };
  },
});
